import { useEffect } from "react";
import { userApp } from "../Admin/Utility";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import $ from "jquery";
import { setload_Screen, Show_MsgBox } from "../ReduxStore/Message";
import { useDispatch } from "react-redux";
function BarcodePreview(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    LoadBarCodeDesign();
  }, []);
  const LoadBarCodeDesign = async () => {
    try {
      await dispatch(setload_Screen(false));
      var CompId = Number(localStorage.ComId);
      await userApp
        .get(
          "Barcode/LoadPrintDesign/" +
            CompId +
            "/" +
            localStorage.db +
            "/" +
            localStorage.Msdb
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            let Product = props.ProductList.filter(
              (Data) => Data.SelectProduct === true
            );
            var BarcodeValue = BarcodeBasedOn(Res.data.objEntCodeFormate);
            Printbarcode(
              BarcodeValue,
              Res.data.objEntCompanyInfo,
              Res.data.objEntState,
              Res.data.objEntPrintDesign,
              Product
            );
          } else {
            AlertMessage(Res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const BarcodeBasedOn = (BarcodeFormate) => {
    var Default = BarcodeFormate.filter((Data) => Data.defaultValue === 1);
    var CodeBasedOn = "";
    switch (Default[0].displayName) {
      case "Product Code":
        CodeBasedOn = "strProCode";
        break;
      case "Product Name":
        CodeBasedOn = "strProName";
        break;
      case "HSN Code":
        CodeBasedOn = "strCommodityCode";
        break;
      case "Unit Name":
        CodeBasedOn = "strUnitName";
        break;
      case "Remark":
        CodeBasedOn = "Remarks";
        break;
      default:
        CodeBasedOn = "dPriceRate";
        break;
    }
    return CodeBasedOn;
  };
  const Printbarcode = (BarcodeValue, Company, State, PrintDesign, Product) => {
    try {
      let Template = props.Template[0];
      var TotalLabels = 0;
      const EntProduct = [];
      for (let i = Product.length - 1; i >= 0; i--) {
        EntProduct.push({
          Qty: Product[i].Qty,
          Barcode: Product[i][BarcodeValue],
          Product: Product[i],
        });
        TotalLabels += Product[i].Qty;
      }
      // Calculate effective width for columns
      const PageWidth = Template.dPageWidth;
      const Lableheight = Template.dLblHeight;
      const ColumnGap = Template.dcolumgap;
      const RowGap = Template.drowgap;
      const Columns = Template.inoofcopy;
      const rows = Math.ceil(TotalLabels / Columns);
      const effectiveWidth = PageWidth;
      // Calculate width for each column
      const LableWidth = (effectiveWidth - (Columns - 1) * ColumnGap) / Columns;
      const PageyHeight = rows * Lableheight + (rows - 1) * RowGap;
      //Page Width  and  Height Calculation
      var doc = new jsPDF({
        orientation: PageyHeight < PageWidth ? "l" : "p",
        unit: "mm",
        format: [PageWidth, PageyHeight],
      });

      //Filter Company Design
      let objEntProString = PrintDesign.filter(
        (DT) => DT.strColumName !== "Barcode" && DT.columType === 2
      );
      //Filter Product Design
      let objEntCompString = PrintDesign.filter(
        (DT) => DT.strColumName !== "Barcode" && DT.columType === 1
      );
      //Filter Barcode Design
      let objEntBarData = PrintDesign.filter(
        (DT) => DT.strColumName === "Barcode"
      )[0];
      // Add PrintContent
      let objEntString = [];
      for (let PS = 0; PS < objEntProString.length; PS++) {
        objEntString.push(AddPrintContent(objEntProString[PS], Product[0], 1));
      }
      for (let CS = 0; CS < objEntCompString.length; CS++) {
        objEntString.push(
          AddPrintContent(objEntCompString[CS], Company[0], State, 2)
        );
      }

      //Print Barcode and Data
      let L = 0;
      for (let i = 0; i < EntProduct.length; i++) {
        // Add PrintContent
        let objEntDataString = [];
        for (let PS = 0; PS < objEntProString.length; PS++) {
          objEntDataString.push(
            AddPrintContent(objEntProString[PS], EntProduct[i].Product, 1)
          );
        }
        for (let CS = 0; CS < objEntCompString.length; CS++) {
          objEntDataString.push(
            AddPrintContent(objEntCompString[CS], Company[0], 2, State)
          );
        }

        for (var j = 0; j < EntProduct[i].Qty; j++) {
          const row = Math.floor(L / Columns);
          const col = L % Columns;
          const left = col * (LableWidth + ColumnGap);
          const top = row * (Lableheight + RowGap);
          L++; /* Increase Printed Labels Count Every Label Print*/
          $("#Barcode").empty();
          for (let EDS = 0; EDS < objEntDataString.length; EDS++) {
            var DT = objEntDataString;
            let dtAlign = DT[EDS].strTextAlign;
            var dtValue =
              DT[EDS].strPrefix + DT[EDS].strprintContent + DT[EDS].strSuffix;
            let dtX = left + DT[EDS].dXAxis;
            let dtY = top + DT[EDS].dYAxis;
            //Print String Data
            doc.setFont(
              DT[EDS].strfont,
              DT[EDS].strFontStyle,
              DT[EDS].strFontWeight
            );
            doc.setFontSize(DT[EDS].iFontSize);
            doc.setTextColor(DT[EDS].strColor);
            doc.text(doc.splitTextToSize(dtValue, DT[EDS].dWidth), dtX, dtY, {
              align: dtAlign,
              maxWidth: LableWidth,
            });
          }
          let BrcodeWidth = Template.dbarcodeWidth;
          let BarcodeHeight = Template.dbarcodeHeight;
          JsBarcode("#Barcode", EntProduct[i].Barcode, {
            format: props.BarcodeFormate,
            height: BarcodeHeight,
            width: objEntBarData.dWidth,
            displayValue: false,
            font: objEntBarData.strfont,
            textAlign: objEntBarData.strTextAlign,
            fontSize: objEntBarData.iFontSize,
            lineColor: objEntBarData.strColor,
            background: "transparent",
            margin: 0,
          });
          var Img = document.getElementById("Barcode");
          // Generate PDF
          doc.addImage(
            Img.src,
            "JPEG",
            left + objEntBarData.dXAxis,
            top + objEntBarData.dYAxis,
            LableWidth < BrcodeWidth ? LableWidth : BrcodeWidth,
            BarcodeHeight
          );
        }
      }
      setTimeout(() => {
        props.Close();
      }, 1000);
      doc.output("dataurlnewwindow", { filename: "Barcode" });
      doc.autoPrint();
    } catch (err) {
      AlertMessage(err, "error");
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const AddPrintContent = (EntString, objEntData, iColumType, State) => {
    let printContent = "";
    if (iColumType === 1) {
      printContent = GetProColumn(
        EntString.strColumName,
        objEntData
      ).Value.toString();
    } else {
      printContent = GetCompanyColumn(
        EntString.strColumName,
        objEntData,
        State
      ).Value.toString();
    }
    EntString = {
      columType: EntString.columType,
      dWidth: EntString.dWidth,
      dXAxis: EntString.dXAxis,
      dYAxis: EntString.dYAxis,
      iColumnId: EntString.iColumnId,
      iFontSize: EntString.iFontSize,
      inOrder: EntString.inOrder,
      printAreaId: EntString.printAreaId,
      strColor: EntString.strColor,
      strColumName: EntString.strColumName,
      strCompanyDB: EntString.strCompanyDB,
      strFontWeight: EntString.strFontWeight,
      strPrefix: EntString.strPrefix,
      strSuffix: EntString.strSuffix,
      strTextAlign: EntString.strTextAlign,
      strprintContent: printContent,
      strfont: EntString.strfont,
      strwordwrap: EntString.strwordwrap,
      strFontStyle: EntString.strFontStyle,
    };
    return EntString;
  };
  const GetProColumn = (strColumName, ProductList) => {
    let strValue = "";
    let strColumn = "";
    switch (strColumName) {
      case "ProCode":
        strValue = ProductList.strProCode;
        strColumn = "strProCode";
        break;
      case "ProName":
        strValue = ProductList.strProName;
        strColumn = "strProName";
        break;
      case "PrintName":
        strValue = ProductList.strPrintName;
        strColumn = "strPrintName";
        break;
      case "CommodityCode":
        strValue = ProductList.strCommodityCode;
        strColumn = "strCommodityCode";
        break;
      case "PriceRate":
        strValue = Number(ProductList.dPriceRate).toFixed(2);
        strColumn = "dPriceRate";
        break;
      case "Discount":
        strValue = Number(ProductList.dDiscount).toFixed(2);
        strColumn = "dDiscount";
        break;
      case "UnitName":
        strValue = ProductList.strUnitName;
        strColumn = "strUnitName";
        break;
      case "Remarks":
        strValue = ProductList.Remarks;
        strColumn = "Remarks";
        break;
      case "RateWithTax":
        strValue = ProductList.dRateWithTax;
        strColumn = "RateWithTax";
        break;
      default:
        break;
    }
    return { Value: strValue, Column: strColumn };
  };
  const GetCompanyColumn = (strColumName, CompanyInfo, State) => {
    let strValue = "";
    let strColumn = "";
    switch (strColumName) {
      case "CompanyName":
        strValue = CompanyInfo.strCompName;
        strColumn = "strCompName";
        break;
      case "Address1":
        strValue = CompanyInfo.strAdd1;
        strColumn = "strAdd1";
        break;
      case "Address2":
        strValue = CompanyInfo.strAdd2;
        strColumn = "strAdd2";
        break;
      case "Address3":
        strValue = CompanyInfo.strAdd3;
        strColumn = "strAdd3";
        break;
      case "Address4":
        strValue = CompanyInfo.strAdd4;
        strColumn = "strAdd4";
        break;
      case "State":
        strValue = State.filter(
          (DT) => DT.iStateId === CompanyInfo.state.toString()
        )[0].strSName;
        strColumn = "strSName";
        break;
      case "Phone":
        strValue = CompanyInfo.strPhone;
        strColumn = "strPhone";
        break;
      case "Pincode":
        strValue = CompanyInfo.strPincode;
        strColumn = "iPincode";
        break;
      case "Mobile":
        strValue = CompanyInfo.strMobile;
        strColumn = "strMobile";
        break;
      case "Email":
        strValue = CompanyInfo.strEmail;
        strColumn = "strEmail";
        break;
      case "TIN":
        strValue = CompanyInfo.strTIN;
        strColumn = "strTIN";
        break;
      case "GST":
        strValue = CompanyInfo.strGST;
        strColumn = "strGST";
        break;
      case "Remarks":
        strValue = CompanyInfo.strRemarks;
        strColumn = "strRemarks";
        break;
      case "Logo":
        strValue = CompanyInfo.imgData;
        strColumn = "imgData";
        break;
      default:
        break;
    }
    return { Value: strValue, Column: strColumn };
  };
}

export default BarcodePreview;
